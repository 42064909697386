import React, { FC, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/named
import { RouteComponentProps } from "@reach/router";
import { Canceler } from "axios";

import {
    AppBanner,
    AppLoader,
    CourseCard,
    AppContainer,
    AppBlankDataHelper,
    AppSectionHeader,
} from "../../components";
import { PublicAPI } from "../../apis";
import { cancelAllPrevRequest, errorToast, itemsPerPage } from "../../utils";
import { ELCourse } from "../../models";
import { useDetectInViewport, useTranslation } from "../../hooks";

import "./assets/styles/styles.scss";

export const CoursePage: FC<RouteComponentProps> = (): JSX.Element => {
    const { t } = useTranslation();

    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ELCourse[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const isHasLoadMore = !loading && totalItems > data.length && hasMore;

    const fetchData = (page?: number, replaceData?: ELCourse[]) => {
        cancelAllPrevRequest(cancelTokenSourcesRef.current);
        setLoading(true);

        const activePage = page || currentPage;

        PublicAPI.getAllElCourse<ELCourse>(
            activePage,
            {
                itemsPerPage,
            },
            (c) => {
                cancelTokenSourcesRef.current.push(c);
            }
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    errorToast(errorMessage);
                } else if (response) {
                    const currentData = replaceData || data;

                    setCurrentPage(activePage + 1);
                    setData([...currentData, ...response.items]);
                    setTotalItems(response.totalItems);

                    if (response.items.length < itemsPerPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const lastRef = useDetectInViewport(isHasLoadMore, () => {
        fetchData();
    });

    const domainList: string[] = [];

    return (
        <div className="course-container">
            <AppBanner />

            <AppContainer className="mt-4">
                <AppSectionHeader
                    title={t("course.section:label.title")}
                    subTitle={t("course.section:label.description")}
                />

                {!!(!loading && !data.length) && <AppBlankDataHelper />}

                <div className="course-container--items">
                    <div className="row">
                        {data.map((item) => {
                            let isNew = false;
                            if (
                                item?.container?.domain &&
                                !domainList.includes(item.container.domain)
                            ) {
                                isNew = true;
                                domainList.push(item.container.domain);
                            }
                            return (
                                <CourseCard
                                    key={item.id}
                                    item={item}
                                    isNew={isNew}
                                    isListView
                                />
                            );
                        })}
                    </div>
                    {isHasLoadMore && <div ref={lastRef} />}
                </div>

                {loading && (
                    <AppLoader
                        containerClassName={
                            currentPage !== 1
                                ? "load-more-loader"
                                : "centerLoader"
                        }
                    />
                )}
            </AppContainer>
        </div>
    );
};
