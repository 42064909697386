import React, { FC, useState } from "react";
import { navigate } from "@reach/router";

import {
    AppCard,
    AppButton,
    AppTextHoverTooltip,
    AppShowMoreButton,
    AppContentModal,
    AppDuration,
    AppUsersList,
} from "..";
import { CommonModelCardProps, ELCourse } from "../../models";
import { getETrans, resolveImage, resolveImageWithStyle } from "../../utils";
import {
    useBuildAssetPath,
    useDateTime,
    useRedirectHandler,
    useTranslation,
} from "../../hooks";
import placeholder from "../../assets/images/placeholders/img-thumb.svg";

import {
    ContainerLogoFileInfo,
    ELCoursePosterFileInfo,
    ShowMoreTextCharLength,
} from "../../../config";

import "./assets/scss/style.scss";
import {
    DefaultImagePlaceHolderEnum,
    useDefaultImgPlaceholder,
} from "../../hooks/useDefaultImgPlaceholder";

export interface CourseCardProps extends CommonModelCardProps<ELCourse> {
    isNew: boolean;
}

export const CourseCard: FC<CourseCardProps> = ({
    item,
    isListView = false,
    isNew = false,
    className = "",
}): JSX.Element => {
    const {
        id,
        imageName = "",
        start,
        elCourseCategory,
        duration,
        totalElTasks = 0,
        totalElExams = 0,
        container,
        speakers = [],
    } = item;

    const name = getETrans(item, "name");
    const description = getETrans(item, "description");

    // hooks
    const { t } = useTranslation();
    const { toGetDay, toGetMonth, toGetYear, toShortTime } = useDateTime();
    const { openRegisterTab } = useRedirectHandler();

    // image
    const { placeHolderImage } = useDefaultImgPlaceholder(
        DefaultImagePlaceHolderEnum.ElearningCourse,
        placeholder
    );
    const basePath = useBuildAssetPath(ELCoursePosterFileInfo, container);
    const style = resolveImageWithStyle(basePath, imageName, placeHolderImage);

    const containerLogoPath = useBuildAssetPath(ContainerLogoFileInfo);
    const containerLogo = resolveImage(
        containerLogoPath,
        container.logoImageName,
        placeHolderImage
    );

    const [showMore, setShowMore] = useState<boolean>(false);
    const [showMoreContainer, setShowMoreContainer] = useState<boolean>(false);

    const renderRegisterButton = (btnClass: string, primary = false) => (
        <AppButton
            className={btnClass}
            variant="primary"
            onClick={() => {
                openRegisterTab(container.domain, `/courses/${id}/view`);
            }}
        >
            {primary
                ? t("public.register.form:registerButton")
                : t("course.list:button.registration")}
        </AppButton>
    );

    return (
        <div
            className={`course-item ${className} col-12 col-sm-6 col-lg-4 col-xl-3 ${
                isListView ? "listView" : "hoverView"
            }`}
        >
            <AppContentModal
                show={showMore || showMoreContainer}
                handleClose={() => {
                    setShowMore(false);
                    setShowMoreContainer(false);
                }}
                icon={"description"}
                title={`course.list:section.title.description`}
                size="lg"
                keyboard
            >
                {showMore ? (
                    <p
                        className="text"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                ) : (
                    <p>{container.description}</p>
                )}
            </AppContentModal>

            <AppCard className="course-item--card p-0" id={String(id)}>
                <div className="banner">
                    <div className="banner--content" style={style}>
                        {isNew && (
                            <div className="new-ribbon">
                                <div className="content">
                                    <span>{t("course.list:badge.new")}</span>
                                </div>
                            </div>
                        )}

                        {start && (
                            <div className="dateBadge">
                                <div className="content">
                                    <div className="day">
                                        <span>{toGetDay(new Date(start))}</span>
                                    </div>
                                    <div className="time">
                                        <span className="month">
                                            {toGetMonth(new Date(start))}
                                        </span>
                                        <span className="clock">
                                            {toShortTime(new Date(start))}
                                        </span>
                                    </div>
                                    <div className="year">
                                        <span>
                                            {toGetYear(new Date(start))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {elCourseCategory && (
                        <div className="category">
                            <div
                                className="content"
                                style={{
                                    "--course-category-background-color":
                                        elCourseCategory.color,
                                    backgroundColor: elCourseCategory.color,
                                }}
                            >
                                <span
                                    style={{
                                        color: elCourseCategory.textColor,
                                    }}
                                >
                                    {getETrans(elCourseCategory, "name")}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="context">
                    <div className="context--inner">
                        <div className="header mb-2">
                            <div className="title">
                                <AppTextHoverTooltip text={name}>
                                    <h3>{name}</h3>
                                </AppTextHoverTooltip>
                            </div>

                            {!isListView && (
                                <div className="actions mr-0 ml-auto">
                                    <AppButton
                                        className="shareBtn"
                                        variant="secondary"
                                        onClick={() => navigate("/course")}
                                    >
                                        <i className="fak fa-share-alt"></i>
                                    </AppButton>
                                </div>
                            )}
                        </div>

                        <div className="desc mb-2">
                            <p
                                id="description"
                                className="text"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />

                            {description &&
                                description.length > ShowMoreTextCharLength && (
                                    <AppShowMoreButton
                                        onClick={() => setShowMore(true)}
                                    />
                                )}
                        </div>

                        <div className="info pt-2">
                            <div className="row">
                                <div className="info--item duration col-auto pl-2 pr-2 pb-2">
                                    <div className="content">
                                        <div className="d-flex">
                                            <div className="content--icon">
                                                <i className="fak fa-hourglass"></i>
                                            </div>
                                            <div className="content--desc">
                                                <span className="count">
                                                    <AppDuration
                                                        duration={duration}
                                                    />
                                                </span>
                                                <span className="field">
                                                    {t(
                                                        "course.form:label.duration"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="info--item exams col-auto pl-2 pr-2 pb-2">
                                    <div className="content">
                                        <div className="d-flex">
                                            <div className="content--icon">
                                                <i className="fak fa-elearning-hat"></i>
                                            </div>
                                            <div className="content--desc">
                                                <span className="count">
                                                    {totalElExams}
                                                </span>
                                                <span className="field">
                                                    {t(
                                                        "course.form:label.exams"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="info--item tasks col-auto pl-2 pb-2">
                                    <div className="content">
                                        <div className="d-flex">
                                            <div className="content--icon">
                                                <i className="fak fa-check-circle-regular"></i>
                                            </div>
                                            <div className="content--desc">
                                                <span className="count">
                                                    {totalElTasks}
                                                </span>
                                                <span className="field">
                                                    {t(
                                                        "course.form:label.tasks"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isListView && (
                            <div className="actions">
                                <div className="registerBtn">
                                    {renderRegisterButton("registerBtn", true)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="additional-info">
                    <div className="additional-info--inner">
                        <div className="users">
                            <AppUsersList
                                users={speakers}
                                hasTitle
                                title="course.list:section.title.speakers"
                            />
                        </div>

                        {container && (
                            <div className="presenters">
                                <div className="presenters--inner">
                                    <div className="title mb-1">
                                        <h4>
                                            <i className="fak fa-handshake-alt-light"></i>
                                            <span>
                                                {t(
                                                    "course.list:label.presenter"
                                                )}
                                            </span>
                                        </h4>
                                    </div>

                                    <div className="content pl-4">
                                        <div className="presenterItem d-flex">
                                            <div className="picture pr-3">
                                                <img src={containerLogo} />
                                            </div>

                                            <div className="details">
                                                <div className="name">
                                                    <AppTextHoverTooltip
                                                        text={container.name}
                                                    >
                                                        <h5>
                                                            {container.name}
                                                        </h5>
                                                    </AppTextHoverTooltip>
                                                </div>

                                                <div className="desc">
                                                    <p>
                                                        {container.description}
                                                    </p>

                                                    {container.description &&
                                                        container.description
                                                            .length >
                                                            ShowMoreTextCharLength && (
                                                            <AppShowMoreButton
                                                                className="mt-1"
                                                                onClick={() =>
                                                                    setShowMoreContainer(
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isListView && renderRegisterButton("registerBtnEnd")}
            </AppCard>
        </div>
    );
};
